import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { RootState } from "store";
import { Store } from "modules/customer/tools/product/store";
import { ProductChapter, selectCurrentChapter, selectFilterStores, selectReferenceDate, selectSelectedPartner, selectSelectedRange, selectSelectedStore } from "modules/customer/tools/product/productSlice";
import { loadRanges, Range } from "./range";
import { logError } from "modules/helpers/logger/loggerSlice";
import { DataWrapper } from "domain/dataWrapper";
import { selectPartnerFiltersVisibility } from "modules/customer/tools/product/partnerFilters/partnerFiltersSlice";

interface FiltersVisibility {
    isVisible: boolean
}
interface LoadRangeSelectionResponse {
    ranges: Range[]
}


export enum StoreRangeFilterStep {
    SelectStore,
    SelectRange
}

export interface StoresSearch {
    name: string
}

interface FiltersState {
    storeRangeFiltersVisibility: FiltersVisibility,
    activeStep: StoreRangeFilterStep,
    storesSearch: StoresSearch,
    ranges: DataWrapper<Range[]>,
    candidateStore?: Store,
    candidateRange?: Range
}

const initialState: FiltersState = {
    storeRangeFiltersVisibility: {
        isVisible: false
    },
    activeStep: StoreRangeFilterStep.SelectStore,
    storesSearch: {
        name: ""
    },
    ranges: { isLoading: false, hasErrors: false, data: [] },
    candidateStore: undefined,
    candidateRange: undefined
};

const storeRangeFiltersSlice = createSlice({
    name: "customer/tools/product/storeRangeFilters",
    initialState,
    reducers: {
        showStoreRangeFilters: (state) => {
            state.storeRangeFiltersVisibility.isVisible = true;
        },
        hideStoreRangeFilters: (state) => {
            state.storeRangeFiltersVisibility.isVisible = false;
        },
        setActiveStep: (state, action: PayloadAction<StoreRangeFilterStep>) => {
            state.activeStep = action.payload;
        },
        clearActiveStep: (state) => {
            state.activeStep = initialState.activeStep;
        },
        setStoresSearch: (state, action: PayloadAction<StoresSearch>) => {
            state.storesSearch = action.payload;
        },
        clearStoresSearch: (state) => {
            state.storesSearch = initialState.storesSearch;
        },
        setCandidateStore: (state, action: PayloadAction<Store>) => {
            state.candidateStore = action.payload;
        },
        clearCandidateStore: (state) => {
            state.candidateStore = initialState.candidateStore;
        },
        clearRanges: (state) => {
            state.ranges = initialState.ranges;
        },
        setCandidateRange: (state, action: PayloadAction<Range>) => {
            state.candidateRange = action.payload;
        },
        clearCandidateRange: (state) => {
            state.candidateRange = initialState.candidateRange;
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadRangeSelection.pending, (state: FiltersState) => {
            state.ranges.isLoading = true;
            state.ranges.hasErrors = false;
            state.ranges.data = initialState.ranges.data;
        });
        builder.addCase(loadRangeSelection.rejected, (state: FiltersState) => {
            state.ranges.isLoading = false;
            state.ranges.hasErrors = true;
            state.ranges.data = initialState.ranges.data;
        });
        builder.addCase(loadRangeSelection.fulfilled, (state: FiltersState, action: PayloadAction<LoadRangeSelectionResponse>) => {
            state.ranges.isLoading = false;
            state.ranges.hasErrors = false;
            state.ranges.data = action.payload.ranges;
        });
    }
});

export const loadRangeSelection = createAppAsyncThunk(
    "customer/tools/product/storeRangeFilters/loadRangeSelection",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const referenceDate = selectReferenceDate(state);
            const partner = selectSelectedPartner(state);
            const selectedStore = selectCandidateStore(state);

            const ranges = await thunkAPI.dispatch(loadRanges(referenceDate, partner, selectedStore));
            const loadStoreOpportunitiesResponse: LoadRangeSelectionResponse = {
                ranges
            };
            return loadStoreOpportunitiesResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Range Selection.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const {
    showStoreRangeFilters,
    hideStoreRangeFilters,
    setActiveStep,
    setStoresSearch,
    setCandidateStore,
    clearActiveStep,
    clearCandidateStore,
    clearStoresSearch,
    clearCandidateRange,
    clearRanges,
    setCandidateRange
} = storeRangeFiltersSlice.actions;

export const resetStoreRangeFilters = (): AppThunk => async (dispatch) => {
    dispatch(storeRangeFiltersSlice.actions.hideStoreRangeFilters());
    dispatch(storeRangeFiltersSlice.actions.clearActiveStep());
    dispatch(storeRangeFiltersSlice.actions.clearStoresSearch());
    dispatch(storeRangeFiltersSlice.actions.clearCandidateStore());
    dispatch(storeRangeFiltersSlice.actions.clearRanges());
    dispatch(storeRangeFiltersSlice.actions.clearCandidateRange());
};

export const clearRangeSelection = (): AppThunk => async (dispatch) => {
    dispatch(storeRangeFiltersSlice.actions.clearRanges());
};

export const selectStoreRangeFiltersVisibility = (state: RootState): FiltersVisibility => {
    return state.customer.tools.product.storeRangeFilters.storeRangeFiltersVisibility;
};

export const selectActiveStep = (state: RootState) => {
    return state.customer.tools.product.storeRangeFilters.activeStep;
};

export const selectStoresSearch = (state: RootState) => {
    return state.customer.tools.product.storeRangeFilters.storesSearch;
};

export const selectCandidateStore = (state: RootState) => {
    return state.customer.tools.product.storeRangeFilters.candidateStore;
};

export const selectRanges = (state: RootState) => {
    return state.customer.tools.product.storeRangeFilters.ranges;
};

export const selectCandidateRange = (state: RootState) => {
    return state.customer.tools.product.storeRangeFilters.candidateRange;
};

export const selectFilteredStores = createSelector(
    (state: RootState) => selectFilterStores(state),
    selectStoresSearch,
    // selectFilter,
    // selectSort,
    (stores, search/*, filter, sort*/) => {
        const { isLoading, hasErrors } = stores;
        const filteredStores: DataWrapper<Store[]> = {
            isLoading,
            hasErrors,
            data: []
        };

        const searchString = search.name.toLowerCase();
        filteredStores.data = stores.data.filter(store =>
            (!searchString || store.name.toLowerCase().includes(searchString)));
        return filteredStores;
    }
);

export const selectTooltipHintText = createSelector(
    (state: RootState) => selectCurrentChapter(state),
    (currentChapter) => {
        let tooltipText = "";
        switch (currentChapter) {
            case ProductChapter.StoreOpportunities:
                tooltipText = "Select a store to view store opportunities";
                break;
            case ProductChapter.RangeOpportunities:
                tooltipText = "Select a product range to view range opportunities";
                break;
            case ProductChapter.ProductStoreFit:
                tooltipText = "Select a store and product to view product store fit";
                break;

        }
        return tooltipText;
    }
);

export const selectOpenModalActiveStep = createSelector(
    (state: RootState) => selectCurrentChapter(state),
    (state: RootState) => selectSelectedRange(state),
    (state: RootState) => selectSelectedStore(state),
    (currentChapter, selectedRange, selectedStore) => {
        let activeStep = StoreRangeFilterStep.SelectStore;
        switch (currentChapter) {
            case ProductChapter.StoreOpportunities:
                activeStep = StoreRangeFilterStep.SelectStore;
                break;
            case ProductChapter.RangeOpportunities:
                activeStep = StoreRangeFilterStep.SelectRange;
                break;
            case ProductChapter.ProductStoreFit:
                activeStep = selectedStore ? StoreRangeFilterStep.SelectRange : StoreRangeFilterStep.SelectStore;
                break;
            default:
                activeStep = selectedRange ? StoreRangeFilterStep.SelectRange : StoreRangeFilterStep.SelectStore;
                break;

        }
        return activeStep;
    }
);

export const selectIsTooltipOpen = createSelector(
    (state: RootState) => selectPartnerFiltersVisibility(state),
    (state: RootState) => selectStoreRangeFiltersVisibility(state),
    (state: RootState) => selectCurrentChapter(state),
    (state: RootState) => selectSelectedRange(state),
    (state: RootState) => selectSelectedStore(state),
    (partnerFiltersVisibility, storeRangeFiltersVisibility, currentChapter, selectedRange, selectedStore) => {
        let isTooltipOpen = false;
        if (partnerFiltersVisibility.isVisible || storeRangeFiltersVisibility.isVisible) {
            return isTooltipOpen;
        }

        switch (currentChapter) {
            case ProductChapter.StoreOpportunities:
                isTooltipOpen = !selectedStore;
                break;
            case ProductChapter.RangeOpportunities:
                isTooltipOpen = !selectedRange;
                break;
            case ProductChapter.ProductStoreFit:
                isTooltipOpen = !selectedStore || !selectedRange;
                break;
            default:
                isTooltipOpen = false;
                break;

        }
        return isTooltipOpen;
    }
);


export default storeRangeFiltersSlice;
