import React from "react";

import Chapter from "pages/customer/tools/product/insight/Chapter";
import Subchapter from "pages/customer/tools/product/insight/Subchapter";

import RangeInsight from "./rangeInsight/RangeInsight";
import Store from "./store/Store";
import { useAppDispatch, useAppSelector } from "store";
import { showStoreRangeFilters } from "modules/customer/tools/product/storeRangeFilters/storeRangeFiltersSlice";
import { selectSelectedRange } from "modules/customer/tools/product/productSlice";
import RangeInsightSubtitle from "./rangeInsight/Subtitle";
import StoreSubtitle from "./store/Subtitle";
import Subtitle from "./Subtitle";

const RangeOpportunities: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectedRange = useAppSelector(selectSelectedRange);

    React.useEffect(() => {
        if (!selectedRange) {
            dispatch(showStoreRangeFilters());
        }
    }, [dispatch, selectedRange]);

    return (
        <>
            {selectedRange && (
                <Chapter
                    title="Range opportunities"
                    subtitle={<Subtitle />}
                    dataCy="range-opportunities-chapter"
                >
                    <Subchapter
                        title="Range insight"
                        subtitle={<RangeInsightSubtitle />}
                        dataCy="range-insight-subchapter"
                    >
                        <RangeInsight />
                    </Subchapter>
                    <Subchapter
                        title="Store"
                        subtitle={<StoreSubtitle />}
                        dataCy="store-subchapter"
                    >
                        <Store />
                    </Subchapter>
                </Chapter>
            )}
        </>
    );
};

export default RangeOpportunities;
