import React from "react";

import { selectSelectedRange } from "modules/customer/tools/product/productSlice";
import { useAppSelector } from "store";
import { Typography } from "@material-ui/core";

const RangeSalesTitle: React.FC = () => {
    const selectedRange = useAppSelector(selectSelectedRange);

    return <Typography variant="h6">{selectedRange?.name} historic, optimised, and estimated sales</Typography>;
};

export default RangeSalesTitle;
