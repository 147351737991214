import React from "react";

import { selectSelectedStore } from "modules/customer/tools/product/productSlice";
import { useAppSelector } from "store";
import { Typography } from "@material-ui/core";

const StoreSalesTitle: React.FC = () => {
    const selectedStore = useAppSelector(selectSelectedStore);

    return <Typography variant="h6">{selectedStore?.name} historic, estimated, and optimised sales</Typography>;
};

export default StoreSalesTitle;
