import { ResultSet } from "@cubejs-client/core";
import { AppThunk } from "appThunk";
import { DateTime } from "luxon";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import { ProductSales } from "modules/customer/tools/product/productSales";
import { Range } from "modules/customer/tools/product/storeRangeFilters/range";

export class RangeMonthlySales {
    public readonly rangeId: string;
    public readonly date: DateTime;
    public readonly sales: ProductSales;

    constructor(
        rangeId: string,
        date: DateTime,
        clientSourcedSales: number,
        estimatedSales: number,
        optimisedSales: number,
        salesHeadroom: number,

    ) {
        this.rangeId = rangeId;
        this.date = date;
        this.sales = new ProductSales(
            date.toISODate(),
            date.toISODate(),
            clientSourcedSales,
            estimatedSales,
            optimisedSales,
            salesHeadroom
        );
    }
}

export const loadStoreMonthlySales = (
    referenceDate: DateTime,
    selectedRange?: Range,
    selectedStoreId?: string
): AppThunk<Promise<RangeMonthlySales[]>> => async (dispatch) => {
    try {
        if (!referenceDate || !selectedRange) {
            return [];
        }

        const startDate = referenceDate.minus({ months: 60 }).startOf('month').startOf('day');
        const endDate = referenceDate.endOf('day');
        const query = {
            measures: [
                "ProductSalesEstimate.SumClientSourcedSales",
                "ProductSalesEstimate.SumEstimatedSales",
                "ProductSalesEstimate.SumOptimisedSales",
                "ProductSalesEstimate.SumSalesHeadroom",
            ],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [startDate, endDate]
            }],
            dimensions: [
                "D_Store.StoreNaturalID",
                "D_Date.MonthStartDate",
            ],
            filters: [{
                member: "ProductSalesEstimate.FK_ProductCategory",
                operator: "equals",
                values: [String(selectedRange.id)]
            }],
            segments: [
                "D_Store.OpenPhysicalStores"
            ],
            order: [["D_Date.MonthStartDate", "asc"]]
        };

        if (selectedStoreId) {
            query.filters.push({
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: [String(selectedStoreId)]
            });
        }

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        return resultSet.rawData().map(row => {
            return new RangeMonthlySales(
                String(row["D_Store.StoreNaturalID"]),
                DateTime.fromISO(row["D_Date.MonthStartDate"], { zone: "utc" }),
                Number(row["ProductSalesEstimate.SumClientSourcedSales"]),
                Number(row["ProductSalesEstimate.SumEstimatedSales"]),
                Number(row["ProductSalesEstimate.SumOptimisedSales"]),
                Number(row["ProductSalesEstimate.SumSalesHeadroom"]),
            );
        });
    } catch (error) {
        dispatch(logError("Error loading RangeMonthlySales.", error));
        throw error;
    }
};
