import React from "react";
import { Box, Typography } from "@material-ui/core";

import { useAppSelector } from "store";
import { selectSelectedPartner, selectSelectedRange, selectProductsTotalSales } from "modules/customer/tools/product/productSlice";

import KPIComparison from "components/visuals/KPIComparison";
import numberFormatter from "utils/numberFormatter";

const SelectedRangeSales = () => {
    const selectedPartner = useAppSelector(selectSelectedPartner);
    const selectedRange = useAppSelector(selectSelectedRange);
    const { data: totalSales, isLoading, hasErrors } = useAppSelector(selectProductsTotalSales);
    const label = `${selectedPartner?.name} headroom value ${selectedRange?.name}`;

    return (
        <Box width="100%" height="100%" data-cy="selected-range-total-headroom">
            <KPIComparison
                isLoading={isLoading}
                hasErrors={hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: "#FFFFFF" }}>
                        {numberFormatter.toGBP(totalSales.totalSalesHeadroom ?? 0, 1)}
                    </Typography>
                }
                targetLabel={label}
                comparisonValue={
                    <Typography variant="h6" component="div" style={{ color: "#FFFFFF" }}>
                        {numberFormatter.toGBP(totalSales.totalOptimisedSales ?? 0, 1)}
                    </Typography>
                }
                comparisonLabel="Optimised sales in the past 12 months"
                differenceValue={
                    <Typography variant="h6" component="div" style={{ color: "#FFFFFF" }}>
                        {numberFormatter.toGBP(totalSales.totalEstimatedSales ?? 0, 1)}
                    </Typography>
                }
                differenceLabel="Estimated sales in the past 12 months"
            />
        </Box>
    );
};

export default SelectedRangeSales;
